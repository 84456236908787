import lsc_logo from './SweetCake-logo.svg';
import './App.css';

function App() {
  return (
    <main className="lsc-app">
      <header className="center-items">
        <h1 hidden>SweetCake</h1>
        <img height="300px" src={lsc_logo} alt="SweetCake logo" />
      </header>
    </main>
  );
}

export default App;
